import { Avatar, Box, Button, CircularProgress, Typography } from "@mui/material";
import ReplyIcon from '@mui/icons-material/Reply';
import React, { useEffect } from "react";
import { getAllOrderBriefings } from "../utils/saveBriefing";
import { saveBriefing } from "../utils/saveBriefing";

const BriefingEnviado = ({formData}) => {
  const [feedback, setFeedback] = React.useState(null);
  const [feedbackEnviado, setFeedbackEnviado] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [briefingsPendentes, setBriefingsPendentes] = React.useState([]);
  const [briefingsButtonLoading, setBriefingsButtonLoading] = React.useState(null);
  const [briefingsPendentesEnviados, setBriefingsPendentesEnviados] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  console.log(formData);
  const direcionaParaOutroBriefing = (art_sku) => {
    const url = new URL(window.location.href); // Cria um objeto URL a partir da URL atual
    url.searchParams.set('produto', art_sku); // Define ou atualiza o parâmetro 'produto'
    window.location.href = url.toString(); // Redireciona para a nova URL
  };


  const getBriefingsPendentes = async () => {
    try {
      const pedido = new URLSearchParams(window.location.search).get('numero_pedido');
      const data = await getAllOrderBriefings(pedido);
  
      setBriefingsPendentes(data);
  
      const newButtonLoadingState = data.reduce((acc, briefing) => {
        acc[briefing.art_sku] = false;
        return acc;
      }, {});
      setBriefingsButtonLoading(newButtonLoadingState);
      const newBriefingsPendentesEnviados = data.reduce((acc, briefing) => {
        acc[briefing.art_sku] = false;
        return acc;
      }, {});
      setBriefingsPendentesEnviados(newBriefingsPendentesEnviados);
    } catch (error) {
      console.error("Erro ao buscar os briefings pendentes:", error);
    }
  };
  useEffect(() => {
    const savedFeedback = localStorage.getItem('feedback');
    if (savedFeedback) {
      setFeedbackEnviado(true);
    }
    getBriefingsPendentes();
  }, []);

  const enviaFeedback = () => {
    setLoading(true);
    const pedido = new URLSearchParams(window.location.search).get('numero_pedido');
    const body = {
      feedback: feedback,
      pedido: pedido
    }
    localStorage.setItem('feedback', feedback);
    fetch("https://hook.us1.make.com/abb5m5vz29ghlqp74oabyv2ghc2c0lpc",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setFeedbackEnviado(true);
      alert("Feedback enviado com sucesso!");
    }
    )
  }
  return (
    <Box>
      <h1 style={{textAlign: "center"}}>Briefing enviado com sucesso! ✅</h1>
      {/* Se houver briefings pendentes e o briefing atual não for uma replicação*/}
      {briefingsPendentes.length > 0 && !formData["Arte Pronta"] && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign="center" fontWeight={"bold"} fontSize={"20px"}>Você tem {briefingsPendentes.length} briefing(s) pendente(s) para enviar. Gostaria de replicar esse briefing para os demais produtos?</Typography>
          <Box>
          {briefingsPendentes.map((briefing, index) => (
            <Box key={index} marginBottom={2} marginTop={3}>
              <Typography fontWeight="bold" fontSize="18px">
                Briefing {index + 1}
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar
                  src={briefing.product_image} 
                  alt="Arte final" 
                  sx={{ width: 64, height: 64 }} // Ajuste o tamanho conforme necessário
                  variant="square" // Pode ser "rounded" ou "circle"
                />
                <Typography fontWeight="bold" fontSize="16px">
                  {briefing.product_name}
                </Typography>
                <Box>
                  {briefingsPendentesEnviados[briefing.art_sku] && <Typography fontWeight="bold" fontSize="16px" color="green">Briefing replicado com sucesso!✅</Typography>}
                  {!briefingsPendentesEnviados[briefing.art_sku] && (
                  <Button variant="contained" color="success" onClick={async ()   => {
                    setBriefingsButtonLoading((prevState) => ({
                      ...prevState,
                      [briefing.art_sku]: true
                    }))
                    await fetch("https://hook.us1.make.com/abb5m5vz29ghlqp74oabyv2ghc2c0lpc", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json"
                      },
                      body: JSON.stringify({
                        feedback: `Replicação de briefing para o produto: ${briefing.art_sku}, pedido: ${briefing.ns_order_id}`
                      })
                    });
                    const data = await saveBriefing(formData, briefing.ns_order_id, briefing.ns_customer_id, briefing.art_sku, uploading, setUploading, () => {
                      setBriefingsPendentesEnviados((prevState) => ({
                        ...prevState,
                        [briefing.art_sku]: true
                      }))
                    }, briefing);
                    setBriefingsButtonLoading((prevState) => ({
                      ...prevState,
                      [briefing.art_sku]: false
                    }))
                    
                  }
                  } style={{marginRight: "10px"}}>
                    {briefingsButtonLoading[briefing.art_sku] ? <CircularProgress size={20} color="secondary"/> : "Replicar Briefing"}
                    </Button>
                    )}
                    <Box margin={2}/>
                    {!briefingsPendentesEnviados[briefing.art_sku] &&<Button variant="contained" color="warning" onClick={() => direcionaParaOutroBriefing(briefing.art_sku)}>Preencher outro briefing</Button>}
                </Box>
              </Box>

            </Box>
        ))}
          </Box>
        </Box>
      )}
      {!feedbackEnviado && (
        <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign={"center"} fontWeight={"bold"} fontSize={"20px"}>Gostaríamos muito de saber sua opinião! O que você achou do briefing? Alguma sugestão de melhoria?</Typography>
          <textarea 
          placeholder="Quais pontos você gostou no briefing? Alguma sugestão para melhorarmos?"
          style={{
            width: "90%", 
            height: "100px", 
            backgroundColor: "transparent", 
            border: "1px solid green", 
            borderRadius: "5px", 
            fontSize: "16px", 
            padding: "10px", 
            fontFamily: "Montserrat",
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px"
          }}
          onChange={(e) => setFeedback(e.target.value)}
          />
          <Box display="flex" alignItems="center">
            <Button variant="contained" color="success" onClick={enviaFeedback}>Enviar feedback</Button><Box width={'10px'}/>{loading && <CircularProgress size={20}/>}
          </Box>
        </Box>
      )}
      <Box margin="20px" textAlign="center">
        <Button variant="contained" color="warning" onClick={() => window.location.href = `https://papello.com.br/account/orders/${new URLSearchParams(window.location.search).get('numero_pedido')}`}>Voltar para área do cliente<ReplyIcon/></Button>
      </Box>

    </Box>
  );
};

export default BriefingEnviado;